<template>
    <div class="advice_container">
        <Header ref="Header"></Header>
        <div class="container">
            <h2 class="advice_title">意见反馈</h2>
            <!-- 问题类型选择 -->
            <div class="advice_data">
                <span class="title">问题类型：</span>
                <ul class="type_list">
                    <li class="type_li">
                        <el-radio v-model="radio" :label="1" class="radio_input">产品建议（功能优化新想法）</el-radio>
                    </li>
                    <li class="type_li">
                        <el-radio v-model="radio" :label="2" class="radio_input">故障反馈（功能不可用）</el-radio>
                    </li>
                    <li class="type_li">
                        <el-radio v-model="radio" :label="3" class="radio_input">其他问题</el-radio>
                    </li>
                </ul>
            </div>
            <!-- 问题输入 -->
            <div class="advice_describe">
                <span class="title">问题描述：</span>
                <textarea class="describe_textarea" placeholder="请您详细描述反馈的对象及理由，对现有的功能不满意？想要新的功能？快来告诉我们吧！（10字以上）" v-model="problem"></textarea>
            </div>
            <!-- 上传图片 -->
            <div class="upload_image">
                <span class="title">上传图片：</span>
                <el-upload
                  class="upload_input"
                  name="file"
                  :action="action"
                  multiple
                  :limit="9"
                  list-type="picture-card"
                  :on-success='handlePictureCardPreview'
                  :on-remove="onremovePic"
                  v-show="isUpload"
                >
                    <i class="el-icon-plus"></i>
                    <!-- <el-button size="small" type="primary">点击上传图片{{dialogImageUrl}}</el-button> -->
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl|$DateFormatimg" alt="">
</el-dialog>
                <!-- <ul class="image_list">
                    <li
                      class="image_li"
                      v-for="(imageUrl, index) in imageList"
                      :key="index"
                      :style="{backgroundImage: 'url(' + imageUrl + ')'}"
                    ></li>
                </ul> -->
                <div class="help_center">
                    <router-link to="/helpCenter">
                        <i class="icon"></i>
                        <span>帮助中心</span>
                    </router-link>
                </div>
            </div>
            <button class="submit_button" @click="submit">提交反馈</button>
        </div>
        <Footer></Footer>
        <div class="tips_box" v-show="isShowTips">{{tipsText}}</div>
    </div>
</template>
<script>
import { adviceInterface, uploadImage } from '@/config/request'
export default {
    name: 'advice',
    data() {
        return {
            accountId: '',
            radio: 1, // 反馈类型选择
            problem: '', // 问题描述
            action: uploadImage, // 图片上传地址
            imageList: [], // 上传图片列表
            isUpload: true, // 是否显示上传图片按钮
            access_token: '',
            tipsText: '', // 提交反馈之后的结果文案显示
            isShowTips: false, // 是否显示提示
            dialogImageUrl:'',
            dialogVisible:false,
            classifys:''
        }
    },
    computed:{
classify(){
    
}
    },
    methods: {
        // 出现提示文案
        showTips(text, isSuccess) {
            this.isShowTips = true
            this.tipsText = text
            setTimeout(()=> {
                this.isShowTips = false
                if(isSuccess) {
                    this.$router.push({
                        path: '/'
                    })
                }
            }, 2000)
        },
        // 提交意见
        submit() {
            if(this.access_token) {
                let files = this.imageList.join(',')
                if(this.problem) { // 已输入文字
                    if(this.problem.length > 5000) {
                        this.showTips('您当前输入字数大于5000~', false)
                        return
                    } else if(this.problem.length < 10) {
                        this.showTips('请您描述的再详细些哦~', false)
                        return
                    }
                    switch (this.radio) {
        case 1:
           this.classifys='PROPOSAL_IMPROVEMENT'
            break;
    case 2:
            this.classifys='FUNCTION_BUG'
            break;
            case 3:
            this.classifys='OTHER'
            break;
        default:
            break;
    }
                    adviceInterface.submitAdvice({
                        classify: this.classifys,
                        deviceInfo:'pc',
                        feedbackDesc: this.problem,
                        pictures: this.imageList.toString(),
                        // accountId: this.accountId
                    }).then(res => {
                        this.showTips('您的反馈已成功提交', true)
                    })
                } else { // 未输入文字
                    this.showTips('请输入问题描述~', false)
                }
                
            } else {
                this.$refs.Header.Login()
            }
            
        },
        // 上传文件
        handlePictureCardPreview(res, file, fileList) {
            console.log(res, file);
            this.imageList.push(res.data.url)
            // this.dialogImageUrl = res.url;
            // this.dialogVisible=true
            console.log(this.imageList);
            if(this.imageList.length > 8) {
                this.isUpload = false
            }
            
        },
        onremovePic(file, fileList){
       let index=  this.imageList.indexOf(file.response.data.url)
       this.imageList.splice(index, 1)
       console.log(this.imageList);
        }
    },
    created() {
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.accountId = userInfo ? userInfo.id : ''
        this.access_token = sessionStorage.getItem('access_token')
    },
}
</script>
<style scoped>
    @import './style/advice.css';
</style>
